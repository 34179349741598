// == Hide text
// ----------------------
@mixin hide-text {
	overflow: hidden;
	&:before {
		display: block;

		width: 0;
		height: 100%;

		content: '';
	}
}
