@charset 'UTF-8';

// Fonts
$opensans: 'Open Sans', sans-serif;

// Colors
$black: #374048;
$blue: #2e9ef5;
$green: #1ecd8d;
$grey: #9299a0;
$orange: #fa7c0d;

// Breakpoints
$breakpoints: (
	'xl': 1440px,
	'lg': 1176px,
	'md': 1008px,
	'sm': 768px,
	'xs': 568px
);