// == Header
// -------------------------------

.header {
	position: relative;
	z-index: 2;

	display: flex;

	background: white;
	box-shadow: 0 9px 14px rgba(0,0,0,.05);

	flex-wrap: wrap;
}

.header__slogan {
	width: 100%;

	color: white;
	background: $blue;
}

.header__sloganText {
	@include media('<sm') {
		display: none;
	}
	@include media('>=sm','<xl') {
		padding: 15px 10px;
	}
	@include media('>=xl') {
		padding: 15px;
	}

	font-size: 16px;
	line-height: 1.25;

	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

.header__inner {
	@include media('<xl') {
		padding: 0 10px;
	}
	@include media('>=xl') {
		padding: 0 15px;
	}

	position: relative;

	display: flex;

	width: 100%;
	max-width: 1440px;
	margin: 0 auto;

	align-items: center;
	justify-content: space-between;
}
