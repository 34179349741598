// == Domain
// -------------------------------

.domain {
	@include media('<sm') {
		padding: 20px 0;
	}
	@include media('>=sm','<md') {
		padding: 30px 0;
	}
	@include media('>=md', '<lg') {
		padding: 40px 0;
	}
	@include media('>=lg') {
		padding: 50px 0;
	}
	@include media('>=lg') {
		background-image: url(../i/domain-bg.png);
		background-repeat: no-repeat;
		background-position: 50% 0;
	}

	background-color: #eef3f6;
}

.domain__intro {
	@include media('<sm') {
		width: 310px;
		height: 269px;
		padding: 10px 16px 96px;
	}
	@include media('>=sm') {
		width: 595px;
		height: 515px;
		padding: 20px 32px 193px;
	}

	display: flex;

	margin: 0 auto;

	color: white;
	background: url(../i/domain-computer.png) no-repeat 50% 100%;
	background-size: contain;

	align-items: center;
	justify-content: center;
}

.domain__intro__logo {
	@include media('<sm') {
		width: 310px;
		height: 269px;
		padding: 10px 16px 96px;
	}
	@include media('>=sm') {
		width: 595px;
		height: 515px;
		padding: 20px 32px 193px;
	}

	display: flex;

	margin: 0 auto;

	color: white;
	background: url(../i/domain-computer-logo.png) no-repeat 50% 100%;
	background-size: contain;

	align-items: center;
	justify-content: center;
}

.domain__caption {
	@include media('<sm') {
		font-size: 18px;

		padding: 20px;
	}
	@include media('>=sm') {
		font-size: 30px;

		padding: 50px;
	}

	font-weight: bold;
	line-height: 1.25;
	text-align: center;
}

.domain__content {
	@include last-margin;
	@include media('<sm') {
		font-size: 17px;

		padding: 20px;
	}
	@include media('>=sm','<md') {
		padding: 30px;
	}
	@include media('>=md','<lg') {
		padding: 40px;
	}
	@include media('<lg') {
		margin-bottom: 40px;
	}
	@include media('>=lg') {
		margin-bottom: 50px;
		padding: 50px;
	}
	@include media('>=xl') {
		font-size: 22px;
	}

	position: relative;

	max-width: 1440px;
	margin: 0 auto;

	text-align: left;

	background: white;
	&:after {
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;

		display: block;

		height: 37px;

		content: '';

		background: url(../i/domain-shadow.png) no-repeat 50% 100%;
	}
	p {
		margin-bottom: 0;
	}
}

.domain__title {
	@include media('<sm') {
		font-size: 22px;
	}
	@include media('>=sm','<md') {
		font-size: 23px;
	}
	@include media('>=md','<lg') {
		font-size: 27px;
	}
	@include media('>=lg') {
		font-size: 30px;
	}

	font-weight: bold;
	line-height: 1.25;

	margin-bottom: .5em;
}

.domain__align {
	text-align: center;
	word-wrap: break-word;
}

.domain__name {
	color: $orange;
}
