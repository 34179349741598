// == List styles reset
// ----------------------
@mixin reset-list {
	margin: 0;
	padding: 0;

	list-style: none;
	li {
		margin: 0;
		&:before {
			display: none;
		}
	}
}
