// == Nav
// -------------------------------

.nav {
	position: relative;

	display: flex;

	width: 100%;

	flex-wrap: wrap;
	justify-content: flex-end;
}

.nav__list {
	@include reset-list;
	@include media('<md') {
		font-size: 14px;
		line-height: 18px;

		display: none;

		width: calc(100% + 20px);
		margin: 0 -10px;

		text-transform: uppercase;
	}
	@include media('>=md') {
		font-weight: bold;
		line-height: 30px;

		display: flex;

		padding: 40px 0;

		text-transform: uppercase;
	}
	@include media('>=md','<lg') {
		font-size: 16px;
		line-height: 25px;
	}
	@include media('>=lg','<xl') {
		font-size: 18px;
	}
	@include media('>=xl') {
		font-size: 20px;
	}
	.nav_open & {
		@include media('<md') {
			display: block;
		}
	}
}

.nav__item {
	&:nth-child(n+2) {
		@include media('>=md','<lg') {
			margin-left: 34px;
		}
		@include media('>=lg','<xl') {
			margin-left: 47px;
		}
		@include media('>=xl') {
			margin-left: 62px;
		}
	}
}

.nav__link {
	@include media('<md') {
		display: block;

		padding: 8px 10px 9px;

		color: #85caff;
		border-top: 1px solid #363d42;
		background: #262d33;
	}
	@include media('>=md') {
		color: $black;
	}
}

.nav__toggle {
	@include media('<sm') {
		margin-top: 16px;
		margin-right: 10px;
		margin-bottom: 16px;
	}
	@include media('>=sm','<md') {
		margin-top: 23px;
		margin-bottom: 24px;
	}
	@include media('>=md') {
		display: none;
	}

	position: relative;

	display: block;

	width: 38px;
	height: 38px;

	cursor: pointer;

	border: 1px solid #d9dbde;
	background: transparent;
	& > i,
	&:before,
	&:after {
		position: absolute;
		left: 8px;

		display: block;

		width: 20px;
		height: 4px;

		content: '';

		border-radius: 2px;
		background: #374048;
	}
	& > i {
		top: 16px;
	}
	&:before {
		top: 8px;
	}
	&:after {
		top: 24px;
	}
	.nav_open & {
		border-color: #262d33;
		background: #262d33;
		& > i,
		&:before,
		&:after {
			background: white;
		}
	}
}
