// == Base
// -------------------------------

*,
:before,
:after {
	box-sizing: border-box;
}

html {
	height: 100%;

	background: white;
}

body {
	@include media('<sm') {
		font-size: 17px;
	}
	@include media('>=md','<xl') {
		font-size: 18px;
	}
	@include media('>=xl') {
		font-size: 20px;
	}

	font-family: $opensans;
	line-height: 1.25;

	position: relative;

	display: flex;
	flex-direction: column;

	min-height: 100%;

	color: $black;

	justify-content: space-between;
}

a {
	transition: color .15s ease;
	text-decoration: none;

	color: $blue;
	.no-touchevents &:hover {
		color: $orange;
	}
}

p {
	margin: 0 0 1.25em;
}

ul {
	@include last-margin;

	margin: 0 0 1.25em;
	padding: 0;

	list-style: none;
	& > li {
		margin: 0 0 .8em 1.5em;
		&:before {
			display: block;
			float: left;

			width: .35em;
			height: .35em;
			margin: .5em 0 0 -1.1em;

			content: '';

			border-radius: 50%;
			background: $blue;
		}
	}
}

ol {
	@include last-margin;

	margin: 0 0 1.25em;
	padding: 0;

	list-style: none;

	counter-reset: list;
	& > li {
		margin: 0 0 .8em 1.5em;

		counter-increment: list;
		&:before {
			font-weight: bold;

			float: left;

			margin: 0 0 0 -1.75em;

			content: counter(list)'.';

			color: $blue;
		}
	}
}

h1 {
	@include media('<sm') {
		font-size: 26px;
	}
	@include media('>=sm','<md') {
		font-size: 36px;
	}
	@include media('>=md') {
		font-size: 42px;
	}
	@include media('>=lg','<xl') {
		font-size: 46px;
	}
	@include media('>=xl') {
		font-size: 48px;
	}

	font-weight: bold;

	margin: 0;
}

h2 {
	@include media('<sm') {
		font-size: 22px;
	}
	@include media('>=sm','<md') {
		font-size: 26px;
	}
	@include media('>=md','<lg') {
		font-size: 32px;
	}
	@include media('>=lg') {
		font-size: 36px;
	}

	font-weight: bold;

	margin: 0 0 .5em;
}

h3 {
	@include media('<sm') {
		font-size: 18px;
	}
	@include media('>=sm','<xl') {
		font-size: 22px;
	}
	@include media('>=xl') {
		font-size: 26px;
	}

	font-weight: bold;

	margin: 0 0 .75em;
}

h4 {
	@include media('<sm') {
		font-size: 17px;
	}
	@include media('>=sm','<xl') {
		font-size: 18px;
	}
	@include media('>=xl') {
		font-size: 22px;
	}

	font-weight: bold;

	margin: 0 0 .75em;
}

table {
	@include media('<sm') {
		font-size: 16px;
	}
	@include media('>=sm','<md') {
		font-size: 17px;
	}
	@include media('>=md') {
		font-size: 18px;
	}

	margin: 0 0 1.25em;
}

th {
	font-weight: bold;

	padding: 13px 15px;

	text-align: left;

	color: white;
	background: $black;
	&:first-child {
		border-left: 1px solid $black;
	}
	&:nth-child(n+2) {
		box-shadow: 1px 0 0 rgba(255,255,255,.1) inset;
	}
}

td {
	padding: 13px 15px;

	border-bottom: 1px solid #eff3f6;
	background: white;
	&:first-child {
		border-left: 1px solid #eff3f6;
	}
	&:last-child {
		border-right: 1px solid #eff3f6;
	}
	tr:first-child & {
		border-top: 1px solid #eff3f6;
	}
	tr:nth-child(2n+1) & {
		background: #f8fafb;
	}
}

.os_list {
	width: 100%;
}
.os_list td {
	text-align: center;
}
.os_list th {
	text-align: center;
}
.os_list .f_left {
	text-align: left;
}

#active {
	color: #fa7c0d;
}

.dom_list {
	width: 100%;
}