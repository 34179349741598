// == Footer
// -------------------------------

.footer {
	@include media('<md') {
		border-bottom: 5px solid $blue;
	}
	@include media('>=md') {
		border-bottom: 6px solid $blue;
	}

	color: white;
	background: #262d33;
}

.footer__inner {
	@include media('<sm') {
		padding: 0 0 20px;
	}
	@include media('>=sm','<md') {
		padding: 35px 0 30px;
	}
	@include media('>=md') {
		padding: 80px 0 76px;
	}

	display: flex;

	max-width: 1440px;
	margin: 0 auto;

	flex-wrap: wrap;
}
