// == Advantages
// -------------------------------

.advantages {
	@include media('<sm') {
		padding: 25px 0;
	}
	@include media('>=sm','<lg') {
		padding: 30px 0;
	}
	@include media('>=lg','<xl') {
		padding: 30px 0;
	}
	@include media('>=xl') {
		padding: 40px 0;
	}

	display: flex;

	max-width: 1440px;
	margin: 0 auto;

	flex-wrap: wrap;
	&-domain {
		padding: 0;
	}
}

.advantages__item {
	@include media('<xs') {
		width: 50%;

		text-align: center;
	}
	@include media('>=xs','<md') {
		width: 33.3333%;
	}
	@include media('<sm') {
		font-size: 12px;

		text-align: center;
	}
	@include media('>=sm','<lg') {
		font-size: 14px;
	}
	@include media('>=md') {
		width: 16.6666%;
	}
	@include media('>=lg') {
		font-size: 20px;
	}
	@include media('<xl') {
		padding: 0 10px;
	}
	@include media('>=xl') {
		padding: 0 15px;
	}

	font-weight: bold;
	line-height: 1.25;

	text-align: left;
	&:before {
		@include media('retina2x') {
			background-image: url(../img/advantages_2@2x.png);
			background-size: 623px 37px;
		}
		@include media('<xs') {
			margin: 0 auto 12px;
		}
		@include media('<sm') {
			margin: 0 auto 12px;
		}

		display: block;

		width: 42px;
		height: 37px;
		margin: 0 0 12px 0;

		content: '';

		background: url(../img/advantages_2.png) no-repeat;
	}
	&:nth-child(n+3) {
		@include media('<xs') {
			margin-top: 25px;
		}
	}
	&:nth-child(n+4) {
		@include media('>=xs','<md') {
			margin-top: 30px;
		}
	}
	&-data:before {
		background-position: 0 0;
	}
	&-specialist:before {
		background-position: -42px 0;
	}
	&-discount:before {
		background-position: -84px 0;
	}
	&-actions:before {
		background-position: -126px 0;
	}
	&-php:before {
		background-position: -168px 0;
	}
	&-premium:before {
		background-position: -210px 0;
	}
	&-security:before {
		background-position: -252px 0;
	}
	&-ssd:before {
		background-position: -296px 0;
	}
	&-support:before {
		background-position: -342px 0;
	}
	&-migrate:before {
		background-position: -388px 0;
	}
	&-control:before {
		background-position: -430px 0;
	}
	&-stat:before {
		background-position: -472px 0;
	}
	&-webmoney:before {
		background-position: -514px 0;
	}
	&-trust:before {
		background-position: -556px 0;
	}
}

.advantages__item_adv {
	margin: -20px 20px 0 20px;
}

.advantages__item_adv2 {
	margin-left: 20px;
}

.advantages__text {
	@include media('<sm') {
		display: none;
	}
	@include media('>=sm','<lg') {
		font-size: 8pt;
	}
	@include media('>=lg','<xl') {
		font-size: 9pt;
	}
	@include media('>=xl') {
		font-size: 10pt;
	}

	line-height: 1.25;

	display: block;

	margin: 10px auto;
	margin-top: 20px;

	text-align: left;

	color: gray;
}
