// == Bottom nav
// -------------------------------

.bnav {
	@include media('<md') {
		width: 100%;
	}
	@include media('>=md') {
		width: 75%;
	}

	display: flex;

	flex-wrap: wrap;
}

.bnav__column {
	@include media('<sm') {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;

		border-bottom: 1px solid #363d42;
	}
	@include media('>=sm') {
		width: 25%;
	}
	@include media('<xl') {
		padding-right: 10px;
		padding-left: 10px;
	}
	@include media('>=xl') {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.bnav__caption {
	@include media('<sm') {
		margin-bottom: 16px;
	}
	@include media('>=sm','<md') {
		margin-bottom: 24px;
	}
	@include media('<md') {
		font-size: 16px;
	}
	@include media('>=md') {
		font-size: 18px;

		margin-bottom: 32px;
	}

	font-weight: bold;

	text-transform: uppercase;
}

.bnav__list {
	@include reset-list;
	@include last-margin;
	@include media('<md') {
		font-size: 16px;
	}
	@include media('>=md') {
		font-size: 18px;
	}
}

.bnav__item {
	&:nth-last-child(n+2) {
		margin-bottom: .4em !important;
	}
}

.bnav__link {
	color: #85caff;
}
