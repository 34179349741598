// == Services
// -------------------------------

.services {
	@include media('<sm') {
		padding-top: 30px;
	}
	@include media('>=sm','<md') {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@include media('>=md', '<lg') {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	@include media('>=lg') {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@include media('<xl') {
		padding-right: 10px;
		padding-left: 10px;
	}
	@include media('>=xl') {
		padding-right: 15px;
		padding-left: 15px;
	}

	max-width: 1440px;
	margin: 0 auto;
}

.services__title {
	@include media('<sm') {
		font-size: 22px;
	}
	@include media('>=sm','<md') {
		font-size: 23px;
	}
	@include media('>=md','<lg') {
		font-size: 27px;
	}
	@include media('>=lg') {
		font-size: 30px;
	}

	font-weight: bold;
	line-height: 1.25;

	margin-bottom: 1em;

	text-align: center;
}

.services__list {
	@include last-margin;
	@include media('>=sm') {
		display: flex;

		flex-wrap: wrap;
	}
	@include media('<xl') {
		margin: 0 -10px;
	}
	@include media('>=xl') {
		margin: 0 -15px;
	}
}

.services__item {
	@include media('<sm') {
		margin-bottom: 15px;
	}
	@include media('>=sm','<xl') {
		width: calc(33.3333% - 20px);
		margin: 0 10px;
	}
	@include media('>=xl') {
		width: calc(33.3333% - 30px);
		margin: 0 15px;
	}

	font-weight: bold;

	display: block;

	padding: 35px 20px 30px;

	text-align: center;

	color: white;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	&:before {
		display: block;

		width: 70px;
		height: 70px;
		margin: 0 auto 20px;

		content: '';

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
	}
	&:hover {
		color: white !important;
	}
	&-virtual {
		background-image: url(../i/service-1.png);
		&:before {
			background-image: url(../i/virtual.svg);
		}
	}
	&-cms {
		background-image: url(../i/service-2.png);
		&:before {
			background-image: url(../i/cms.svg);
		}
	}
	&-vps {
		background-image: url(../i/service-3.png);
		&:before {
			background-image: url(../i/vps.svg);
		}
	}
}

.services__caption {
	@include media('<sm') {
		font-size: 18px;
	}
	@include media('>=sm','<md') {
		font-size: 20px;
	}
	@include media('>=md','<lg') {
		font-size: 22px;
	}
	@include media('>=lg') {
		font-size: 24px;
	}

	line-height: 1.25;

	text-transform: uppercase;
}

.services__price {
	@include media('<md') {
		font-size: 18px;
	}
	@include media('>=md') {
		font-size: 20px;
	}

	line-height: 1.25;
}

.services__priceValue {
	@include media('<sm') {
		font-size: 24px;
	}
	@include media('>=sm','<md') {
		font-size: 26px;
	}
	@include media('>=md','<lg') {
		font-size: 28px;
	}
	@include media('>=lg') {
		font-size: 30px;
	}
}
