// == Logo
// -------------------------------

.logo {
	@include hide-text;

	display: block;
	&-header {
		@include media('<sm') {
			width: 130px;
			height: 44px;
		}
		@include media('>=sm','<md') {
			width: 174px;
			height: 59px;
		}
		@include media('>=md') {
			width: 247px;
			height: 84px;
		}
		@include media('<xl') {
			left: 10px;
		}
		@include media('>=xl') {
			left: 15px;
		}

		position: absolute;
		top: 13px;

		background: url(../img/logo.svg) no-repeat 0 50%;
		background-size: contain;

		cursor: pointer;
		z-index: 2;
	}
	&-footer {
		@include media('<sm') {
			width: 140px;
			height: 42px;
		}
		@include media('>=sm','<md') {
			width: 150px;
			height: 45px;
		}
		@include media('<md') {
			order: 0;
		}
		@include media('>=md','<lg') {
			width: 134px;
			height: 40px;
		}
		@include media('>=lg','<xl') {
			width: 156px;
			height: 47px;
		}
		@include media('>=xl') {
			width: 178px;
			height: 54px;
		}

		background: url(../img/logo-footer.svg) no-repeat 0 50%;
		background-size: contain;
	}
}
