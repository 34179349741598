// == Copyright
// -------------------------------

.copyright {
	@include media('<sm') {
		width: 100%;
		margin-top: 20px;
	}
	@include media('<md') {
		order: 1;
	}
	@include media('>=md') {
		width: 100%;
	}
	@include media('>=md','<lg') {
		margin-top: 20px;
	}
	@include media('<lg') {
		font-size: 14px;
	}
	@include media('>=lg') {
		margin-top: 25px;
	}
	@include media('>=lg','<xl') {
		font-size: 16px;
	}
	@include media('>=xl') {
		font-size: 18px;
	}
	p {
		@include media('<md') {
			margin-bottom: .5em;
		}
		@include media('>=md') {
			margin-bottom: 1.25em;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
