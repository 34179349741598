// == User nav
// -------------------------------

.usernav {
	@include reset-list;
	@include media('<sm') {
		font-size: 14px;

		display: none;

		width: calc(100% + 20px);
		margin: 0 -10px;

		text-transform: uppercase;
	}
	@include media('>=sm') {
		font-size: 16px;
		font-weight: bold;

		position: absolute;
		top: -50px;
		right: 0;

		display: flex;
	}

	line-height: 1.25;
	.nav_open & {
		@include media('<sm') {
			display: block;
		}
	}
}

.usernav__link {
	@include media('<sm') {
		padding: 10px;
	}
	@include media('>=sm') {
		padding: 15px 20px;
	}

	display: block;

	transition: all .15s ease;

	color: white;
	.no-touchevents &:hover {
		color: white;
	}
	&-help {
		background: $blue;
		&:before {
			@include media('<sm') {
				margin-top: -2px;
				margin-bottom: -1px;
			}

			font-family: iconfont;
			font-size: 20px;
			font-weight: normal;
			line-height: 1;

			position: relative;

			display: inline-block;

			margin-right: 8px;

			content: '\e903';
			vertical-align: top;
		}
		.no-touchevents &:hover {
			color: #d0f1ff;
		}
	}
	&-cp {
		background: $orange;
		&:before {
			@include media('<sm') {
				margin-top: -2px;
				margin-bottom: -1px;
			}

			font-family: iconfont;
			font-size: 20px;
			font-weight: normal;
			line-height: 1;

			position: relative;

			display: inline-block;

			margin-right: 8px;

			content: '\e904';
			vertical-align: top;
		}
		.no-touchevents &:hover {
			background: $green;
		}
	}
	&-exit {
		background: #b8c5cf;
		&:before {
		  @include media('<sm') {
				margin-top: -2px;
				margin-bottom: -1px;
		  }

			font-family: iconfont;
			font-size: 20px;
			font-weight: normal;
			line-height: 1;

			position: relative;

			display: inline-block;

			margin-right: 8px;

			content: '\ea14';
			vertical-align: top;
        }
		.no-touchevents &:hover {
			background: darken(#b8c5cf, 10%);
		}
	}
}
