// == Social
// -------------------------------

.social {
	@include media('<sm') {
		width: 100%;
		margin-top: 20px;
	}
	@include media('<md') {
		order: 2;
	}

	display: flex;
}

.social__item {
	&:nth-child(n+2) {
		@include media('<sm') {
			margin-left: 18px;
		}
		@include media('>=sm','<lg') {
			margin-left: 7px;
		}
		@include media('>=lg','<xl') {
			margin-left: 10px;
		}
		@include media('>=xl') {
			margin-left: 15px;
		}
	}
}

.social__link {
	@include media('<lg') {
		font-size: 23px;
	}
	@include media('>=lg') {
		font-size: 28px;
	}

	line-height: 1;

	display: block;

	color: white;
	&:before {
		font-family: iconfont;

		display: inline-block;
	}
	.no-touchevents &:hover {
		color: $orange;
	}
	&-twitter:before {
		content: '\e909';
	}
	&-gplus:before {
		content: '\ea8c';
	}
	&-vk:before {
		content: '\ea98';
	}
	&-fb:before {
		content: '\ea91';
	}
}
