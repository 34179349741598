// == Siteinfo
// -------------------------------

.siteinfo {
	@include media('<sm') {
		margin-top: 20px;
	}
	@include media('>=sm','<md') {
		margin-top: 35px;
	}
	@include media('<md') {
		width: 100%;
	}
	@include media('>=md') {
		width: 25%;
	}
	@include media('<xl') {
		padding: 0 10px;
	}
	@include media('>=xl') {
		padding: 0 15px;
	}

	display: flex;

	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}
